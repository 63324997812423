import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useRichText from "../hooks/useRichTexts";
import Layout from "../fragments/layout/Layout";
import useSeoQuery from "../queries/useSeoQuery";
import Seo from "../components/seo"

const useEstudio = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulEstudio {
        edges {
          node {
            titulo
            texto {
              raw
            }
            imagen {
              gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);

  return data;
};

const Estudio = () => {
  const data = useEstudio();
  const seo = useSeoQuery();

  return (
    <Layout>
      <Seo
        title="Estudio"
        lang="es"
        titleSEO={seo.estudioTitleSeo}
        description={seo.estudioDescriptionSeo.estudioDescriptionSeo}
      />
      <div className="estudio-container">
        <h1>{data.allContentfulEstudio.edges[0].node.titulo}</h1>
        <div className="estudio-img estudio-image-xs">
          <GatsbyImage
            image={getImage(
              data.allContentfulEstudio.edges[0].node.imagen.gatsbyImageData
            )}
            alt=""
          />
        </div>
        <div className="estudio-text">
          {useRichText(data.allContentfulEstudio.edges[0].node.texto)}
        </div>
        <div className="estudio-img estudio-image-lg">
          <GatsbyImage
            image={getImage(
              data.allContentfulEstudio.edges[0].node.imagen.gatsbyImageData
            )}
            alt=""
          />
        </div>
      </div>
    </Layout>
  );
};

export default Estudio;
